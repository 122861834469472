@import "../../assets/scss/styles.scss";

.navbar {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100vw;
  box-sizing: border-box;
  z-index: 10;

  .navbarTop {
    display: flex;
    position: relative;
    justify-content: space-between;
    align-items: center;
    background-color: $outer-space;
    width: -webkit-fill-available;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    padding: 15px 13px 21px 12px;
    min-height: 62px;
    box-sizing: border-box;

    @include tablet {
      justify-content: flex-start;
    }
  }

  .navbarBottom {
    display: flex;
    width: -webkit-fill-available;
    justify-content: space-between;
    padding: 11px 10px 11px 13px;
    background: #d8d8d8;

    @include tablet {
      display: none;
    }

    .filterBtn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: $outer-space;
      transition: 0.15s ease-in-out;
      cursor: pointer;
      z-index: 1;
      border-radius: 4px;
      height: 32px;
      width: 86px;
      color: $white;
      padding: 7px 10px 7px 12px;
      font: normal bold 14px/20px $font-roboto;

      &.open {
        background: $desert-storm;
        color: $pale-sky;
      }

      p {
        margin: 0;
      }

      .closeButton {
        height: 16px;
        width: 16px;
      }
    }

    &.open {
      p {
        margin: 0;
      }
    }

    .returnBtn {
      display: flex;
      justify-content: center;
      align-items: center;
      font: normal bold 14px/20px $font-roboto;

      svg {
        transform: rotate(180deg);
        margin-right: 7px;
      }
    }
  }

  .filterTab {
    display: none;
    position: absolute;
    overflow: auto;
    bottom: 0;
    max-height: calc(100vh - 180px);
    transform: translateY(calc(100% + 10px));
    margin: 10px 10px 0 12px;
    background: $white;
    border: 1px solid rgb(216, 221, 230);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    padding: 11px 13px 34px 11px;

    &.open {
      display: flex;
      flex-direction: column;
      width: -webkit-fill-available;

      @include tablet {
        display: none;
      }
    }

    .titleButton {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin: 0 0 16px;

      p {
        font: normal 500 12px/16px $font-roboto;
        color: $pale-sky;
        text-transform: uppercase;
        margin: 0;
      }

      button {
        margin-top: 2px;
        font: normal bold 14px/16px $font-roboto;
        color: $outer-space;
      }
    }
  }

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;

    .icon {
      display: none;

      @include tablet {
        display: block;
      }
    }

    .mainLogo {
      display: block;

      @include tablet {
        display: none;
      }
    }
  }

  .searchMobile {
    display: flex;

    @include tablet {
      display: none;
    }

    .totalPermits {
      margin-right: 50px;
    }

    .toggleBtn {
      width: 18px;
      height: 18px;
      position: relative;
      transform: rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: #d8d8d8;
        border-radius: 2px;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 0px;
          transform-origin: left center;
        }

        &:nth-child(2) {
          top: 8px;
          transform-origin: left center;
        }

        &:nth-child(3) {
          bottom: 0;
          transform-origin: left center;
        }
      }

      &.open {
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 1px;
            left: 3px;
          }

          &:nth-child(2) {
            width: 0%;
            opacity: 0;
          }

          &:nth-child(3) {
            transform: rotate(-45deg);
            top: 14px;
            left: 3px;
          }
        }
      }
    }
  }

  .searchDesktop {
    display: none;

    @include tablet {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      &Inputs {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 10px;
        max-width: 960px;
      }
    }

    @include desktop {
      flex-wrap: nowrap;

      &Inputs {
        flex-wrap: nowrap;
        margin-bottom: 0px;
        max-width: 1073px;
      }

      &Permits {
        display: flex;
      }
    }
  }

  .totalPermits {
    margin: 0 6px 0 6px;
    font: normal 500 14px/16px $font-roboto;
    color: $white;
  }

  .subLogo {
    display: none;

    @include tablet {
      display: block;
    }
  }

  .menuOverlay {
    display: flex;
    position: absolute;
    flex-direction: column;
    justify-content: space-between;
    top: 62px;
    background: $outer-space;
    z-index: 10;
    width: 100vw;
    height: calc(100vh - 62px);

    @include tablet {
      display: none;
    }

    .menuList {
      display: flex;
      appearance: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      margin-top: 42px;

      li {
        margin-bottom: 38px;
        font: normal normal 18px/20px $font-roboto;

        &:last-child {
          margin-bottom: 0;
        }

        a {
          text-decoration: none;
          color: $white;
        }
      }
    }

    .menuBottom {
      .shareBox {
        width: calc(100% - 64px);
        margin: 0 auto 20px;
        border: 1px solid #ececeb;

        h3 {
          display: inline-block;
          margin: 0;
          padding: 0 10px;
          transform: translateX(10px) translateY(-10px);
          background-color: $outer-space;
          font: normal bold 14px/14px $font-roboto;
          color: $desert-storm;
        }

        ul {
          list-style-type: none;
          display: flex;
          align-items: center;
          margin: 5px 5px 15px;
          padding: 0;

          li {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 41px;
              height: 41px;
              border-radius: 100%;
              border: 2px solid #ececeb;
            }
          }
        }
      }

      .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 13px 20px 15px;
        background-color: $outer-space;
        font: normal normal 14px/16px $font-roboto;
        color: $desert-storm;

        @include tablet {
          padding: 17px 20px 36px;
        }

        .footerLinks {
          list-style-type: none;
          display: flex;
          margin: 0 0 15px;
          padding: 0;

          li {
            border-right: 1px solid $pale-sky;

            &:last-child {
              border-right: 0;
            }
          }

          a {
            display: block;
            margin: 0 10px;
            font-weight: bold;
            color: $desert-storm;
          }
        }
      }
    }
  }
}
