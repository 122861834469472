@import "../../assets/scss/styles.scss";

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mapWrapper {
  flex: 1;
  height: 100%;

  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.4);

    .ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;

      div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 64px;
        height: 64px;
        margin: 3px;
        border: 3px solid #fff;
        border-radius: 50%;
        animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #fff transparent transparent transparent;

        &:nth-child(1) {
          animation-delay: -0.45s;
        }

        &:nth-child(2) {
          animation-delay: -0.3s;
        }

        &:nth-child(3) {
          animation-delay: -0.15s;
        }
      }
    }

    .text {
      color: $white;
      font: normal bold 15px/17px $font-roboto;
    }
  }

  .permitMobilePanel {
    display: block;
    position: fixed;
    bottom: 31px;
    background-color: $white;
    width: calc(100vw - 23px);
    left: 50%;
    transform: translateX(-50%);
    border-radius: 4px;
    border: 1px solid #979797;
    padding: 4px 4px 18px;
    min-height: 30px;
    box-sizing: border-box;

    @include tablet {
      display: none;
    }

    .container {
      .address {
        background: $outer-space;
        color: $white;
        font: normal bold 14px/16px $font-roboto;
        padding: 21px 14px 17px;

        p {
          margin: 0;
        }
      }

      .permits {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font: normal bold 14px/16px $font-roboto;
        padding: 18px 0 0 12px;

        p {
          margin: 0;
        }

        button {
          display: flex;
          align-items: center;
          justify-content: center;
          background: #f75c06;
          height: 30px;
          width: 47px;
          border-radius: 15px;

          div {
            h5 {
              font: normal bold 14px/16px $font-roboto;
            }

            .metaContainer {
              display: flex;
              font: normal normal 14px/16px $font-roboto;

              span {
                flex: 1;

                &:last-child {
                  flex: 3;
                  font: normal bold 14px/16px $font-roboto;
                }
              }
            }
          }
        }
      }
    }

    &.open {
      height: calc(100vh - 116px);
      width: 100vw;
      border-radius: 0;
      border: none;
      padding: 0;
      bottom: 0;
      z-index: 2;

      .container {
        height: calc(100% - 70px);

        .permits {
          flex-direction: column;
          justify-content: flex-start;
          height: calc(100% - 70px);
          position: relative;
          margin: 0;
          box-sizing: border-box;
          padding: 6px;
          list-style: none;
          overflow-y: scroll;

          li {
            width: 100%;

            button {
              height: fit-content;
              width: 100%;
              border-radius: 0;
              background-color: $white;
              padding: 7px 16px 15px 16px;

              &:hover {
                background-color: $desert-storm;
              }

              &:focus {
                background-color: $desert-storm;
              }

              div {
                text-align: left;
                width: 100%;

                h5 {
                  margin-top: 0;
                }
              }
            }
          }
        }
      }

      .meta {
        position: absolute;
        width: 100%;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        background: $outer-space;
        padding: 15px 0;
        box-sizing: border-box;

        .metaBtn {
          display: flex;
          align-items: center;
          height: 44px;
          padding: 7px 4px 7px 14px;
          background-color: $blaze-orange;
          border: 2px solid $white;
          border-radius: 20px;
          font: normal normal 18px/21px $font-roboto;
          color: $white;

          svg {
            margin-left: 10px;
          }
        }
      }
    }

    .permitWidget {
      height: calc(100vh - 185px); // footer height
      display: flex;
      flex-direction: column;
      background-color: $white;

      .header {
        margin: 0;
        padding: 21px 15px 17px;
        background-color: $outer-space;
        font: normal bold 14px/16px $font-roboto;
        color: $white;
      }

      .body {
        padding: 0 20px;
        font: normal normal 16px/21px $font-roboto;
        color: #2d383f;
        overflow: auto;

        .row {
          display: flex;
          margin: 20px 0;

          .col {
            flex: 1;
          }
        }

        .rowFull {
          flex-direction: column;

          .col:first-child {
            margin-bottom: 10px;
          }

          .col:last-child {
            margin-bottom: 30px;
          }
        }

        .tab {
          overflow: hidden;

          & .tabLabel {
            margin-bottom: 0 !important;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #d8d8d8;

            p {
              margin: 1em 0 1em 1em;
              font: normal normal 16px/21px "Roboto", sans-serif;
              font-weight: bold;
              text-align: left;
            }

            svg {
              width: 10px;
              margin-right: 10px;
              transform: rotate(0);

              path {
                fill: $cod-gray;
              }
            }
          }

          & .tabContent {
            padding: 0 1em;
            background: white;
            transition: all 0.35s;
          }

          &.active {
            background-color: #d8d8d8;

            svg {
              transform: rotate(180deg);
            }
          }
        }

        .lineSeparator {
          display: block;
          width: 100%;
          height: 1px;
          background-color: $geyser;
        }

        p {
          margin: 0;
          width: 60%;
        }
      }
    }
  }
}

.map {
  width: 100%;
  height: 100%;

  div[class*="mapboxgl-popup-tip"] {
    transform: translateX(5px);
  }

  div[class*="markerPopup"] {
    background-color: #f79a33;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    cursor: pointer;
    border: 10px solid white;
  }

  div[class*="mapboxgl-popup-content"] {
    padding: 0;
    border: 1px solid #ffffff;
    border-radius: 5px;
    font: normal normal 14px/14px $font-roboto;
    // overflow: hidden;

    [class*="markerPopup"] {
      position: absolute;
      left: -28px;
      top: -38px;
      z-index: -1;
    }

    h4 {
      margin: 0;
      padding: 15px;
      background-color: #2e393e;
      font: normal bold 12px/16px $font-roboto;
      color: #ffffff;
      border-radius: 5px 5px 0 0;
    }

    ul {
      list-style-type: none;
      padding: 0;
      margin: 10px;
      max-height: 250px;
      overflow: auto;

      li {
        h5 {
          margin: 0 0 5px;
          font: normal bold 12px/12px $font-roboto;
          color: #000;
        }

        div[class="meta"] {
          display: flex;

          span {
            min-height: 15px;
            display: block;
            color: #000;

            &:first-child {
              width: 80px;
              font: normal normal 10px/13px $font-roboto;
            }

            &:last-child {
              flex: 1;
              font: normal bold 10px/13px $font-roboto;
            }
          }
        }

        > button {
          width: 100%;
          display: block;
          text-align: left;
          padding: 15px 10px;
          border-radius: 5px;

          &:hover,
          &[class*="is-active"] {
            background-color: #ececeb;
          }
        }
      }
    }
  }
}

.customActions {
  position: absolute;
  bottom: 40px;
  right: 50%;
  transform: translateX(50%);
  display: flex;

  @include tablet {
    right: 15px;
    transform: translateX(0);
  }
}

.customActions__btn {
  display: flex;
  align-items: center;
  height: 36px;
  padding: 7px;

  background-color: $white;
  border: 1px solid $desert-storm;
  box-sizing: border-box;
  border-radius: 4px;
  font: normal 500 14px/16px $font-roboto;
  text-transform: uppercase;
  color: $outer-space;

  svg {
    margin-right: 9px;
  }

  & + .customActions__btn {
    margin-left: 12px;
  }
}

.searchAreaBtn {
  bottom: 86px;
}

.noDataOverlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($black, 0.5);
}

.noDataContainer {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;

  p {
    color: $white;
    font: normal normal 16px/16px $font-roboto;
    margin: 0 0 12px;
  }

  @include tablet {
    transition: width 0.2s ease-in-out;

    &.widgetOpen {
      width: calc(100% - 365px);
    }
  }
}

.noDataBtn {
  color: $white;
  background-color: transparent;
  border: 2px solid $white;
  font: normal bold 16px/16px $font-roboto;
  padding: 10px 18px;
  margin-top: 20px;

  &:hover {
    background-color: $blaze-orange;
  }
}
