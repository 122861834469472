@import '../../assets/scss/styles.scss';

.inputDate {
  position: relative;

  @include tablet {
    margin-right: 10px;
  }

  .inputDateBtn {
    width: 100%;
    height: 32px;
    padding: 0 11px;
    border: 1px solid $geyser;
    border-radius: 4px;
    background-color: $pale-sky;
    font: normal normal 14px/16px $font-roboto;
    color: $white;

    @include tablet {
      width: 170px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      svg {
        width: 10px;

        path {
          fill: $white;
        }
      }
    }
  }

  .inputDateDropdown {
    margin-top: 5px;
    width: 100%;
    background-color: $white;
    border-radius: 5px;
    font: normal bold 12px/12px $font-roboto;

    @include tablet {
      position: absolute;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }

    ul {
      list-style-type: none;
      margin: 10px 0;
      padding: 0;
      max-height: 300px;
      overflow: auto;

      li {
        margin: 2.5px 6px;
        border-radius: 4px;

        &:first-child {
          margin-top: 0;
        }

        button {
          display: block;
          padding: 5px;
          width: 100%;
          height: 32px;
          padding: 0 10px;
          font: normal normal 14px/18px $font-roboto;
          text-align: left;
        }

        &:hover,
        &.active {
          background-color: $desert-storm;
        }
      }
    }

    .trigger {
      display: flex;
      justify-content: center;
      padding: 0 10px 10px;
      font: normal bold 16px/16px $font-roboto;

      .dateFilterBtn {
        padding: 5px 22px 6px;
        background-color: $blaze-orange;
        border-radius: 15px;
        color: $white;
      }
    }
  }

  .dateRange {
    display: flex;
    padding: 2px 14px 9px 23px;
    justify-content: space-between;
    background-color: $white;
    border-radius: 4px;

    @include tablet {
      position: absolute;
      flex-direction: column;
      top: 167px;
      left: 180px;
      padding: 11px;
      border: 1px solid $geyser;
    }

    .dateRangeContainer {
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    label {
      display: block;
      margin-bottom: 2px;
      font: normal 500 10px/10px $font-roboto;
      text-transform: uppercase;
      color: $outer-space;
    }

    input {
      height: 32px;
      width: 137px;
      border: 1px solid $pale-sky;
      border-radius: 4px;
      padding: 0 11px;
      box-sizing: border-box;
      font: normal normal 14px/14px $font-roboto;

      @include tablet {
        width: 146px;
      }
    }

    div[class="react-datepicker"] {
      border: 1px solid $geyser;
      border-radius: 4px;
    }

    div[class*="react-datepicker__header"] {
      background-color: $outer-space;
    }

    div[class*="react-datepicker__triangle"] {
      border-bottom-color: $desert-storm;
    }

    div[class="react-datepicker__current-month"],
    div[class="react-datepicker__day-name"] {
      color: $white;
    }

    div[class*="react-datepicker__day--in-selecting-range"] {
      background-color: rgba($blaze-orange, 0.5);
    }

    div[class*="react-datepicker__day--selected"],
    div[class*="react-datepicker__day--keyboard-selected"],
    div[class*="react-datepicker__day--in-range"],
    div[class*="react-datepicker__day--in-selecting-range"]:hover {
      background-color: $blaze-orange;
      color: $black;
    }
  }
}