/** breakpoint vars (for mixins, css styling) | mobile first */
$mobile: 375px;
$tablet: 801px;
$desktop: 1280px;

$breakpoints: (
  tablet: $tablet,
  desktop: $desktop
);

:root {
  --breakpoint-tablet: #{$tablet};
  --breakpoint-desktop: #{$desktop};
}
