@import "../../assets/scss/styles.scss";

.overlay {
  display: none; // HIDDEN ON MOBILE FOR NOW.
  position: absolute;
  cursor: pointer;
  bottom: 0;
  width: 100vw;
  height: calc(100vh - 117px);
  z-index: 9;
  background-color: black;
  opacity: 0.5;

  @include tablet {
    display: none;
  }

  &.close {
    display: none;
  }
}

.sideWidget {
  // display: flex; // HIDDEN ON MOBILE FOR NOW.
  display: none;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
  flex-direction: column;
  width: calc(100vw - 52px);
  background-color: $desert-storm;
  min-height: calc(100vh - 360px); // nav height
  height: max-content;
  transition: transform 0.2s ease-in-out;
  z-index: 9;
  overflow: visible;
  bottom: 0;

  &.close {
    display: none;
  }

  @include tablet {
    display: flex;
    width: 365px;
    transform: translateX(0%);
    z-index: 1;
    left: 0;
    height: 100%;

    &.close {
      display: flex;
      transform: translateX(-365px);
    }
  }

  .toggleBtn {
    display: none;
    width: 27px;
    height: 26px;
    position: absolute;
    right: -27px;
    top: 30px;
    background: $athens-gray;
    border-radius: 0 8px 8px 0;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    z-index: 1;

    @include tablet {
      display: block;
    }

    span {
      display: block;
      position: absolute;
      font-size: 17px;
      line-height: 1;
      color: $rolling-stone;
      border-radius: 9px;
      margin-left: 8px;
      opacity: 1;
      left: 0;
      top: 4px;
      transition: 0.25s ease-in-out;

      &.open {
        display: flex;
      }

      &.close {
        display: none;
      }
    }

    &.open span {
      &.open {
        display: none;
      }

      &.close {
        display: flex;
      }
    }
  }

  .aboutWidget {
    flex: 1;
    display: flex;
    flex-direction: column;

    .header {
      height: 122px;
      position: relative;

      @include tablet {
        height: 240px;
      }

      .background {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }

      .headerMain {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.7);

        .mainLogo {
          display: none;

          @include tablet {
            display: block;
          }
        }

        .mainLogoMobile {
          display: block;

          @include tablet {
            display: none;
          }
        }
      }
    }

    .body {
      padding: 24px 28px 0;

      @include tablet {
        padding: 30px;
      }

      p {
        font: normal normal 16px/20px $font-roboto;
        color: $tuna;

        @include tablet {
          color: $outer-space;
          margin-bottom: 1em;
        }

        a {
          font-weight: bold;
          color: $outer-space;
        }

        &:first-child {
          margin-top: 0;
          margin-bottom: 17px;

          @include tablet {
            margin-bottom: 1em;
          }
        }

        &:last-child {
          margin-bottom: 26px;

          @include tablet {
            margin-bottom: 1em;
          }
        }
      }
    }

    .meta {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: max-content;

      .metaBtn {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 7px 4px 7px 14px;
        background-color: $blaze-orange;
        border: 2px solid $white;
        border-radius: 20px;
        font: normal normal 18px/21px $font-roboto;
        color: $white;
        margin-bottom: 26px;

        @include tablet {
          margin-bottom: 0;
        }

        svg {
          margin-left: 10px;
        }
      }

      .shareBox {
        width: calc(100% - 64px);
        margin-bottom: 20px;
        border: 1px solid #44484c;
        display: none;

        @include tablet {
          width: calc(100% - 60px);
          display: block;
        }

        h3 {
          display: inline-block;
          margin: 0;
          padding: 0 10px;
          transform: translateX(10px) translateY(-10px);
          background-color: $desert-storm;
          font: normal bold 14px/14px $font-roboto;
          color: #44484c;
        }

        ul {
          list-style-type: none;
          display: flex;
          align-items: center;
          margin: 5px 5px 15px;
          padding: 0;

          li {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 41px;
              height: 41px;
              border-radius: 100%;
              border: 2px solid #44484c;
            }
          }
        }
      }
    }
  }

  .permitWidget {
    height: calc(100% - 79px); // footer height
    display: flex;
    flex-direction: column;
    border: 2px solid $desert-storm;
    background-color: $desert-storm;

    .header {
      margin: 0;
      padding: 20px;
      background-color: #6e787f;
      font: normal bold 16px/20px $font-roboto;
      color: $white;
    }

    .body {
      padding: 0 20px;
      font: normal normal 16px/21px $font-roboto;
      color: #2d383f;
      overflow: auto;

      .row {
        display: flex;
        margin: 20px 0;

        .col {
          flex: 1;
        }
      }

      .rowFull {
        flex-direction: column;

        .col:first-child {
          margin-bottom: 10px;
        }

        .col:last-child {
          margin-bottom: 30px;
        }
      }

      .tab {
        overflow: hidden;

        & .tabLabel {
          margin-bottom: 0 !important;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid $pale-sky;

          p {
            margin: 1em 0 1em 1em;
            font: normal normal 16px/21px "Roboto", sans-serif;
            font-weight: bold;
            text-align: left;
          }

          svg {
            width: 10px;
            margin-right: 10px;
            transform: rotate(0);

            path {
              fill: $cod-gray;
            }
          }
        }

        & .tabContent {
          padding: 0 1em;
          background: $desert-storm;
          transition: all 0.35s;
        }

        &.active {
          background-color: $pale-sky;

          p {
            color: $white;
          }

          svg {
            transform: rotate(180deg);

            path {
              fill: $white;
            }
          }
        }
      }

      .lineSeparator {
        display: block;
        width: 100%;
        height: 1px;
        background-color: $geyser;
      }

      p {
        margin: 0;
        width: 60%;
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 13px 20px 15px;
    background-color: #151515;
    font: normal normal 14px/16px $font-roboto;
    color: $pale-sky;

    @include tablet {
      padding: 17px 20px 36px;
    }

    .footerLinks {
      list-style-type: none;
      display: flex;
      margin: 0 0 15px;
      padding: 0;

      li {
        border-right: 1px solid $pale-sky;

        &:last-child {
          border-right: 0;
        }
      }

      a {
        display: block;
        margin: 0 10px;
        font-weight: bold;
        color: $pale-sky;
      }
    }
  }
}
