.default {
  display: flex;
  width: 100vw;
  height: 100vh;
  position: fixed;

  .container {
    flex: 1;
    display: flex;
    flex-direction: column;

    .content {
      display: flex;
      position: relative;
      width: 100%;
      height: 100%;
    }
  }
}
