$black: #000000;
$white: #FFFFFF;
$tuna: #383B41;
$pale-sky: #6E787F;
$desert-storm: #ECECEB;
$blaze-orange: #F75C06;
$cod-gray: #151515;
$geyser: #D8DDE6;
$outer-space: #2E383F;
$rolling-stone: #7C8085;
$athens-gray: #F1F1F2;