@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../../assets/fonts/roboto/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}