body {
  margin: 0;
}

button {
  appearance: inherit;
  background-color: transparent;
  padding: 0;
  border: 0;
  cursor: pointer;
}