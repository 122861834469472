//-----------------------------------------------------------------------
//  BREAKPOINTS
//  usage:
//    @include breakpoint($width) {
//      ...your styles here
//    }
//-----------------------------------------------------------------------

// USING: $breakpoints_keyless: (0, 750px, 1280px, 1440px);
@mixin breakpoint($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		@media (min-width: #{map-get($breakpoints, $breakpoint)}) {
			@content;
		}
	}

	@else {
		@media (min-width: $breakpoint) {
			@content;
		}
	}
}

@mixin tablet {
	@include breakpoint($tablet) {
		@content;
	}
}

@mixin desktop {
	@include breakpoint($desktop) {
		@content;
	}
}
