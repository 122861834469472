@import "../../assets/scss/styles.scss";

$modalToggleBtnSize: 30px;

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba($black, 0.85);
  z-index: 3;

  .modalContainer {
    position: relative;
    width: 435px;
  }

  .modalContent {
    background-color: $white;
    border: 1px solid $white;
    border-radius: 4px;
    box-sizing: border-box;
    overflow: hidden;

    .header {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 16px 16px 13px;
      background-color: $outer-space;

      svg {
        width: 247px;
      }
    }

    .body {
      padding: 18px 22px 42px 24px;

      p {
        margin: 0 0 35px;
        font: normal normal 16px/19px $font-roboto;
      }

      .form {
        h3 {
          margin: 0 0 20px;
          font: normal bold 16px/19px $font-roboto;
          color: $tuna;
        }

        .field {
          display: flex;
          align-items: center;

          input {
            flex: 1;
            height: 41px;
            margin-right: 10px;
            padding: 12px 10px 10px;
            background-color: $pale-sky;
            border: 1px solid $geyser;
            box-sizing: border-box;
            font: normal normal 16px/19px $font-roboto;
            color: $white;

            &::-webkit-input-placeholder {
              /* Chrome/Opera/Safari */
              color: $white;
            }
            &::-moz-placeholder {
              /* Firefox 19+ */
              color: $white;
            }
            &:-ms-input-placeholder {
              /* IE 10+ */
              color: $white;
            }
            &:-moz-placeholder {
              /* Firefox 18- */
              color: $white;
            }
          }

          button {
            min-width: 113px;
            height: 41px;
            padding: 11px 21px;
            background-color: $outer-space;
            border: 1px solid #979797;
            border-radius: 4px;
            box-sizing: border-box;
            font: normal 700 16px/19px $font-roboto;
            color: $white;
          }
        }
      }
    }
  }

  .modalCloseBtn {
    position: absolute;
    top: -#{$modalToggleBtnSize / 2};
    right: -#{$modalToggleBtnSize / 2};
    height: $modalToggleBtnSize;
    width: $modalToggleBtnSize;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    border-radius: 50%;

    svg {
      height: #{$modalToggleBtnSize - 2};
      width: #{$modalToggleBtnSize - 2};
      fill: $outer-space;
    }

    &:hover {
      svg {
        fill: $blaze-orange;
      }
    }

    &:focus {
      outline: none;
    }
  }
}
