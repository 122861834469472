@import '../../assets/scss/styles.scss';

.inputType {
  position: relative;
  margin-bottom: 7px;

  @include tablet {
    margin-right: 10px;
    margin-bottom: 0;
  }

  .inputTypeBtn,
  .inputTypeText {
    width: 100%;
    height: 32px;
    padding: 0 11px;
    border: 1px solid $geyser;
    border-radius: 4px;
    background-color: $pale-sky;
    font: normal normal 14px/16px $font-roboto;
    color: $white;

    @include tablet {
      width: 230px;
    }

    div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;

      svg {
        width: 10px;

        path {
          fill: $white;
        }
      }
    }
  }

  .inputTypeText {
    box-sizing: border-box;
  }

  .inputTypeDropdown {
    margin-top: 5px;
    width: 100%;
    background-color: $white;
    border-radius: 5px;
    font: normal bold 12px/12px $font-roboto;
    overflow: hidden;

    @include tablet {
      position: absolute;
      box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    }

    .loadingText,
    .empty {
      margin: 0;
      padding: 20px 15px;
      color: #757575;
      text-transform: uppercase;
    }

    ul {
      list-style-type: none;
      margin: 10px;
      padding: 0;
      max-height: 300px;
      overflow: auto;

      li {
        margin: 10px 0 15px;
        font: normal normal 14px/16px $font-roboto;

        &:first-child {
          margin-top: 5px;
        }

        label {
          display: flex;

          span {
            display: block;
            margin-top: 2px;
            margin-left: 8px;
            cursor: pointer;
          }
        }
      }
    }

    .trigger {
      display: flex;
      justify-content: center;
      padding: 0 10px 10px;
      font: normal bold 16px/16px $font-roboto;

      .typeFilterBtn {
        padding: 5px 22px 6px;
        background-color: $blaze-orange;
        border-radius: 15px;
        color: $white;
      }
    }
  }
}