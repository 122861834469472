@import "../../assets/scss/styles.scss";

.inputPlace {
  position: relative;
  margin-right: 10px;
  width: 85%;

  @include tablet {
    width: max-content;
  }

  form {
    position: relative;
  }

  input {
    min-width: 261px;
    width: -webkit-fill-available;
    height: 32px;
    box-sizing: border-box;
    padding: 0 34px 0 11px;
    border: 1px solid $geyser;
    border-radius: 4px;
    background-color: $pale-sky;
    color: $white;
    font-size: 16px;

    &::placeholder {
      color: $white;
    }

    @include tablet {
      font-size: 14px;
      width: 290px;
    }
  }

  &.open {
    min-width: 261px;
    width: 74.152%;
    margin-right: 0;
  }
}

.inputDropdown {
  position: absolute;
  margin-top: 6px;
  width: 100%;
  background-color: $white;
  border: 1px solid $geyser;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  font: normal bold 14px/16px $font-roboto;

  .searchingText,
  .locationNotFoundText {
    margin: 0;
    padding: 20px 15px;
    color: $pale-sky;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 13px 11px;

    li {
      margin: 5px 0;
    }

    button {
      display: block;
      min-height: 32px;
      width: 100%;
      text-align: left;
      padding: 8px 13px;
      background-color: $desert-storm;
      border-radius: 4px;
      font-weight: normal;
      cursor: pointer;
      transition: background-color 0.1s ease-in;

      &[class*="active"] {
        background-color: darken($desert-storm, 10%);
      }
    }
  }
}

.dataList {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100%;
  margin-top: 6px;
  padding: 10px 11px 13px;
  background-color: $white;
  border: 1px solid $geyser;
  border-radius: 4px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  font: normal bold 14px/16px $font-roboto;
}

.dataListOption {
  margin: 5px 0;
  display: block;
  text-align: left;
  padding: 8px 13px;
  background-color: $desert-storm;
  border-radius: 4px;
  font-weight: normal;
  cursor: pointer;

  &:focus,
  &:active {
    background-color: red;
  }
}

.dropdownBtn {
  display: block;
  width: 100%;
  text-align: left;
  padding: 8px 13px;
  background-color: $desert-storm;
  border-radius: 4px;
  font-weight: normal;
  cursor: pointer;
}

.inputBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  position: absolute;
  top: 16px;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 5px;
  background-color: $outer-space;

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: #d8d8d8;
    }
  }
}
